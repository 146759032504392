<template>
  <div class="blog-overview">
    <AppBreadcrumbs class="blog-overview__container container" />

    <div class="blog-overview__container container">
      <h1
        v-if="strapiData?.blogOverview?.PageTitle"
        class="blog-overview__heading heading heading--1"
      >
        {{ strapiData?.blogOverview?.PageTitle }}
      </h1>

      <p
        v-if="strapiData?.blogOverview?.Description"
        class="blog-overview__description"
      >
        {{ strapiData?.blogOverview?.Description }}
      </p>

      <section
        v-if="blogOverviews?.length"
        class="blog-overview__blog-categories"
      >
        <BlogCategory
          v-for="(category, index) in blogOverviews"
          :key="category"
          :category="category"
          :lazy="index > 2"
        />
      </section>

      <section
        v-if="blogPages?.length"
        class="blog-overview__blog-items"
      >
        <div
          class="blog-overview__blog-grid"
        >
          <BlogTile
            v-for="(blog, index) in blogPages"
            :key="blog"
            :blog="blog"
            :lazy="index > 2 && !blogOverviews?.length"
          />
        </div>

        <PaginatorPages
          v-if="strapiData?.blogPages?.pageInfo?.pageCount > 1"
          class="blog-overview__blog-pages"
          :current-page="strapiData?.blogPages?.pageInfo?.page"
          :total-pages="strapiData?.blogPages?.pageInfo?.pageCount"
        />
      </section>

      <section
        v-else-if="recentBlogs?.length"
        class="blog-overview__recent-blog-items recent-blog-items"
      >
        <div class="recent-blog-items__heading heading heading--1">
          Recent verschenen
        </div>

        <div
          class="recent-blog-items__grid"
        >
          <BlogTile
            v-for="blog in recentBlogs"
            :key="blog"
            :blog="blog"
            :lazy="false"
          />
        </div>
      </section>

      <!--  -->
    </div>
  </div>
</template>

<script setup lang="ts">
import blogOverviewQuery from '~layers/app/graphql/blogOverview.gql'

const meta = inject('meta', ref())

const status: string = 'PUBLISHED'

const route = useRoute()

const page = ref(parseInt(route.query.p || 1))
const strapiData = ref(null)

const { public: config } = useRuntimeConfig()
const locale = config.strapiLocale
const { t } = useI18n({ useScope: 'global' })

async function fetchData() {
  const response = await useAsyncQuery<any>(blogOverviewQuery, {
    documentId: meta.value.strapi_id,
    locale,
    status,
    page: page.value,
  })
  strapiData.value = response.data.value
}

await fetchData()

watch(() => route.query.p, async (p) => {
  page.value = parseInt(p || 1)
  await fetchData()
})

const blogOverviews = computed(() => strapiData.value?.blogOverviews?.filter((category: any) => !!category) || [])
const blogPages = computed(() => strapiData.value?.blogPages?.nodes?.filter((blog: any) => blog) || [])
const recentBlogs = computed(() => strapiData.value?.recent?.filter((blog: any) => blog) || [])

const pageTitle = computed(
  () => strapiData.value?.blogOverview?.PageTitle || '',
)

const metaTitle = computed(
  () => strapiData.value?.blogOverview?.MetaTitle || pageTitle.value,
)

const metaDescription = computed(
  () => strapiData.value?.blogOverview?.MetaDescription || pageTitle.value + ' | ' + t('nuxtSiteConfig.description'),
)

const { setBreadcrumbs } = useBreadcrumbs()
setBreadcrumbs({
  label: metaTitle.value,
  parents: meta.value.parents,
})

const { pageView } = useDataLayer()
pageView('blog-overview', metaTitle.value)

useHead({
  title: metaTitle,
  meta: [
    { hid: 'description', name: 'description', content: metaDescription },
    { hid: 'robots', name: 'robots', content: strapiData.value?.blogOverview?.MetaData?.MetaRobots?.replace('_', ', ') },
  ],
})

const { canonical } = useSeo()
canonical(computed(() => route.path))
</script>

<style src="~layers/app/pages/~blog-overview.css" />
